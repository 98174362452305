/* ========================================================================== */
// ALL REQUIRED IMPORTS
/* ========================================================================== */
// Vue
// Packages
import { _GettersTree, defineStore } from 'pinia';
// Context / Stores / Routers
// Components / Classes / Controllers / Services
// Assets
// Constants / Models / Interfaces / Types
import type { PlaylistStoreActions, PlaylistStoreState } from '../../@types/stores.d'; // '@types/stores.d';
import type { Track } from '../../@types/components/models.d'; // '@types/models.d';
// Utils / Methods / Mocks / Decorators
import { sortTracks } from '@utils/general';
// Styles

/* ========================================================================== */
// INTERNAL HELPERS, INTERFACES, VARS & SET UP
/* ========================================================================== */
/* ========================================================================== */
// DEFINING THE `APP MODULE` STORE
/* ========================================================================== */
export const usePlaylistStore = defineStore<
   string,
   PlaylistStoreState,
   _GettersTree<PlaylistStoreState>,
   PlaylistStoreActions
>('playlist', {
   // arrow functions are recommended for `state` to get full type inference
   state: (): PlaylistStoreState => {
      return {
         playlistName: '', // TODO **[G]** :: Do something to validate the string and only allow letters, nums, and dashes
         selectedTracks: [], // TODO **[G]** :: Create a `localSession` backup that this will check/pull from first after a hard refresh
      };
   },
   actions: {
      addTrack(track: Track): void {
         track.hasBeenSelected = true;
         this.selectedTracks.push(track);
         this.selectedTracks = sortTracks(this.selectedTracks);
      },
      clearPlaylist(): void {
         this.playlistName = '';

         if (this.selectedTracks.length) {
            // TODO :: iterate through the tracks and re-add them to the search results

            console.debug(
               `Clearing ${this.selectedTracks.length} selected tracks from the playlist and adding them back to the search results.`,
            );

            this.selectedTracks = [];
         }
      },
      containsTrack(track: Track): boolean {
         const hasTrack: boolean = this.selectedTracks.some((selectedTrack: Track) => {
            const isThisEqual: boolean =
               selectedTrack.name === track.name &&
               selectedTrack.album.name === track.album.name &&
               selectedTrack.artists[0].name === track.artists[0].name;

            return isThisEqual;
         });

         return hasTrack;
      },
      removeTrack(track: Track): void {
         track.hasBeenSelected = false;

         this.selectedTracks = this.selectedTracks.filter((selected: Track) => {
            return selected.name !== track.name;
         });

         if (this.selectedTracks.length > 1) {
            this.selectedTracks = sortTracks(this.selectedTracks);
         }
      },
      setPlaylistName(playlistName: string): void {
         this.playlistName = playlistName;
      },
   },
   getters: {},
});
