<template>
   <section class="search-results-wrapper">
      <div
         class="search-results-container"
         :class="getSearchResultsContainerClasses()"
         data-testid="$search-results-container"
      >
         <div class="flex flex-row justify-between">
            <h1 :class="getSearchResultsHeaderClasses()">Search Results</h1>
            <p v-if="appStore.isMobile" class="cursor-pointer select-none" @click="navigate()">
               playlist &gt;
            </p>
         </div>
         <div class="search-results-list" :class="getSearchResultsListClasses()">
            <div
               v-if="!searchStore.searchResults.length && !searchStore.showNoResultsMessage"
               data-testid="$search-results-start"
            >
               <p class="call-to-action">Submit a search term to get started.</p>
            </div>
            <div v-if="searchStore.showNoResultsMessage" data-testid="$search-results-empty">
               <p class="call-to-action">No results found. Please try a different search term.</p>
            </div>
            <!--
               NOTE :: Do I want to add tracks pagination?
               If so, how do I want to handle it?
            -->
            <TrackInfo
               v-for="track in searchStore.searchResults"
               v-else
               :key="track.id"
               :track="track"
            />
         </div>
      </div>
      <div class="search-results-action-buttons md:mr-6 mt-4">
         <ActionButton
            v-for="button in actionButtons"
            :key="button.id"
            :button="button"
            :disabled="isButtonDisabled()"
         />
      </div>
   </section>
</template>

<!-- This has to have the full <script></script> tags. Otherwise, the linter freaks out. -->
<script lang="ts" src="./SearchResults.ts"></script>

<!-- Add "scoped" attribute to limit the SCSS to this component only. -->
<style lang="scss" scoped src="./SearchResults.scss" />
