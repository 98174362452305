/* ========================================================================== */
// ALL REQUIRED IMPORTS
/* ========================================================================== */
// Vue
// Packages
import { StatusCodes } from 'http-status-codes';
import axios, { AxiosError, AxiosResponse } from 'axios';
import chalk, { ChalkInstance } from 'chalk';
import cookie from 'cookie';
// import { getReasonPhrase, getStatusCode, ReasonPhrases, StatusCodes } from "http-status-codes";
import queryString, { ParsedQuery } from 'query-string';
// Context / Stores / Routers
// Components / Classes / Controllers / Services
// Assets
// Constants / Models / Interfaces / Types
import {
   NetlifyAction,
   PlaylistCollection,
   // SavePlaylistResponse,
   SearchResults,
   UserInfo,
   UserPlaylistInfo,
} from '@/constants/global';
import { ParamsError } from '../@types/components/models.d';
// Utils / Methods / Mocks / Decorators
import { isAxiosError } from '@utils/general';
// import { logger } from "@utils/logger.js";
// Styles

/* ========================================================================== */
// INTERNAL HELPERS, INTERFACES, VARS & SET UP
/* ========================================================================== */
const debugChalk: ChalkInstance = chalk.blue.italic;
const errorChalk: ChalkInstance = chalk.black.bgRed;
const infoChalk: ChalkInstance = chalk.black.bgCyan;
const successChalk: ChalkInstance = chalk.black.bgGreen;
const warnChalk: ChalkInstance = chalk.black.bgYellow;

/* ========================================================================== */
// DEFINING THE `SPOTIFY` UTIL
/* ========================================================================== */
export const spotify = {
   async getAccessToken(code: string): Promise<boolean> {
      console.debug(
         debugChalk(
            '[DEBUG] spotify.getAccessToken: Attempting to get the access tokens from Spotify...',
         ),
         { code },
      );

      try {
         const response: AxiosResponse = await axios.get(
            `/.netlify/functions/getAccessToken?code=${code}`,
         );

         console.debug(debugChalk('[DEBUG] spotify.getAccessToken: Request succeeded.'), {
            response,
         });

         return response.status === StatusCodes.OK && response.data.isAuthorized;
      } catch (err) {
         const error = err as AxiosError;
         // logger.error('spotify.getAccessToken: There was an error talking to the Netlify function/endpoint.', { error });
         console.error('🚀--BLLR?: GET ACCESS TOKEN ERROR ->', { error }); // TODO **[G]** :: 🚀--BLLR?: REMOVE ME!!!
      }

      return false;
   },
   async getUserInfo(): Promise<UserInfo> {
      console.info(
         infoChalk('[INFO] spotify.getUserInfo: Attempting to get the user info from Spotify...'),
      );

      let userInfo = {} as UserInfo;

      try {
         const response: AxiosResponse<UserInfo> = await axios.get(
            '/.netlify/functions/getUserInfo',
         );

         userInfo = response.data;
      } catch (err) {
         const error = err as AxiosError;

         // logger.error('spotify.getUserInfo: There was an error talking to the Netlify function/endpoint.', { error });
         console.error('🚀--BLLR?: GET USER INFO ERROR ->', { error }); // TODO **[G]** :: 🚀--BLLR?: REMOVE ME!!!
      }

      return userInfo;
   },
   async getUserPlaylists(userId: string): Promise<UserPlaylistInfo> {
      console.info(
         infoChalk(
            '[INFO] spotify.getUserPlaylists: Attempting to get the users playlists from Spotify...',
         ),
      );

      let userPlaylistInfo: UserPlaylistInfo = {} as UserPlaylistInfo;

      try {
         if (!userId) {
            console.error(
               errorChalk('[ERROR] spotify.getUserPlaylists: The user ID is incorrect or missing.'),
            );

            throw Error('spotify.getUserPlaylists: The user ID is incorrect or missing.');
         }

         const response: AxiosResponse<UserPlaylistInfo> = await axios.get(
            `/.netlify/functions/getUserPlaylists?userId=${userId}`,
         );

         userPlaylistInfo = response.data;

         console.debug(
            successChalk(
               `[DEBUG] spotify.getUserPlaylists: Success! Num playlists: ${userPlaylistInfo.numPlaylists}`,
            ),
         );
      } catch (err) {
         const error = err as AxiosError;

         // logger.error('spotify.getUserPlaylists: There was an error talking to the Netlify function/endpoint.', { error });
         console.error('🚀--BLLR?: GET USER PLAYLISTS ERROR ->', { error }); // TODO **[G]** :: 🚀--BLLR?: REMOVE ME!!!

         if (isAxiosError(error)) {
            userPlaylistInfo.shouldRefreshToken = await spotify.shouldRefreshAccessToken(error);
         }
      }

      return userPlaylistInfo;
   },
   async hasPreviousStoredToken(): Promise<boolean> {
      console.debug(
         debugChalk(
            '[DEBUG] spotify.hasPreviousStoredToken: Checking for a previously stored Spotify token...',
         ),
      );

      try {
         const response: AxiosResponse = await axios.get(
            `/.netlify/functions/getAccessToken?code=${NetlifyAction.CheckForStoredKey}`,
         );

         // TODO **[G]** :: Do stuff here to check if the token hasn't expired, if it has then do stuff here to refresh it
         return response.status === StatusCodes.OK && response.data.isAuthorized;
      } catch (err) {
         const error = err as AxiosError;
         // logger.error('spotify.hasPreviousStoredToken: There was an error talking to the Netlify function/endpoint.', { error });

         console.error(
            errorChalk(
               '[ERROR] spotify.hasPreviousStoredToken: There was an error checking for a previously stored Spotify token.',
            ),
            { error },
         ); // TODO **[G]** :: Convert me to a logger writing method
      }

      return false;
   },
   async login(): Promise<void> {
      console.info(infoChalk('[INFO] Attempting to log into the Spotify auth service...'));

      try {
         const response: AxiosResponse = await axios.get('/.netlify/functions/login');
         // logger.debug('spotify.login: Netlify function/endpoint response:', { response });
         window.location.href = response.data.redirectURL;
      } catch (err) {
         const error = err as AxiosError;

         // logger.error('spotify.login: There was an error talking to the Netlify function/endpoint.', { error });
         console.error(
            errorChalk('[ERROR] spotify.login: There was an error logging into Spotify.'),
            { error },
         ); // TODO **[G]** :: Convert me to a logger writing method
      }
   },
   async logout(): Promise<void> {
      // do the logout clean up / delete password stuff here
      // return a value to the FE so that it can update the proper store(s)
   },
   processCallback(): boolean {
      console.debug(
         debugChalk(
            `[DEBUG] spotify.processCallback: Received request to handle Spotify redirect. Processing...`,
         ),
      );

      const { search } = window.location;
      const params = queryString.parse(search) as ParsedQuery;
      // TODO **[G]** :: is `.cookie` just a custom name that I've given it?
      // and cookies, in general, are just specific/custom named fields that I attach to the `document`?
      const parsedCookie: Record<string, string> = cookie.parse(document.cookie);

      // if the state value coming from Spotify/the URI doesn't match what we sent them
      // then short circuit and don't log the user in
      // TODO **[G]** :: should I clean up the cookie? should it be only if this state check fails or overall once I'm done with it?
      if (params?.state !== parsedCookie.spotify_auth_state) {
         console.error(
            errorChalk(
               '[ERROR] The URI state value & the cookie state value did not match! Make sure nobody is tampering with your shit!',
            ),
            { spotifyCallbackParams: params },
         );

         return false;
      }

      if (params?.error === ParamsError.AccessDenied) {
         console.warn(
            warnChalk(
               '[WARN] The user rejected the Spotify permissions. Not logging the user in but instead showing the warning.',
            ),
            { spotifyCallbackParams: params },
         );

         return false;
      }

      console.debug(
         debugChalk(
            `[DEBUG] spotify.processCallback: The user accepted the Spotify permissions. About to retrieve the access tokens...`,
         ),
         { spotifyCallbackParams: params },
      );

      return true;
   },
   async refreshAccessToken(): Promise<void> {
      try {
         const response: AxiosResponse = await axios.get(
            `/.netlify/functions/getAccessToken?code=${NetlifyAction.RefreshAccessToken}`,
         );

         console.debug(`🚀--BLLR? -----------------------------------------------------🚀--BLLR?`);
         console.debug(
            `🚀--BLLR? -> file: Spotify.ts:235 -> refreshAccessToken -> response->`,
            response,
         );
         console.debug(`🚀--BLLR? -----------------------------------------------------🚀--BLLR?`);
      } catch (err) {
         const error = err as Error;
         console.error(
            errorChalk(
               `[ERROR] spotify.refreshAccessToken: There was a problem with the SPM API while refreshing the authorization.`,
            ),
            { error },
         );
      }
   },
   savePlaylist(collection: PlaylistCollection): void /* SavePlaylistResponse */ {
      // do stuff
   },
   async search(term: string): Promise<SearchResults | undefined> {
      let results: SearchResults | undefined;

      try {
         if (await spotify.hasPreviousStoredToken()) {
            console.debug(
               debugChalk(
                  `[DEBUG] Spotify.search: Previous token found. Commencing search for "${term}"...`,
               ),
            );

            const response: AxiosResponse<SearchResults> = await axios.get(
               `/.netlify/functions/search?term=${term}.`,
            );

            console.debug(
               successChalk(`[DEBUG] Spotify.search: Successfully responded with tracks:`),
               { numTracks: response?.data?.tracks?.items?.length },
            );

            results = response.data;
         } else {
            console.warn(
               warnChalk(
                  `[WARN] Spotify.search: No previous token found. Ignoring search request for "${term}".`,
               ),
            );

            // TODO **[G]** :: Do and/or return stuff to show the UI warning that the search cannot be completed.
            // I should log the user out and clean some things up, like `userInfo` in the store?
            // maybe the auth_state cookie as well?
            // Then the user should do "something", probably log in again

            results = undefined;
         }
      } catch (err) {
         const error = err as AxiosError;

         console.error(
            errorChalk(`[ERROR] Spotify.search: There was a problem executing the search.`),
            {
               term,
               error,
            },
         );

         results = undefined;
      }

      return results;
   },
   async shouldRefreshAccessToken(error: AxiosError): Promise<boolean> {
      const isUnauthorized = error.response?.status === StatusCodes.UNAUTHORIZED;
      const hasPreviousToken: boolean = await spotify.hasPreviousStoredToken();
      return isUnauthorized && hasPreviousToken;
   },
};
