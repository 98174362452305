<template>
   <button
      class="action-button font-bold px-8 py-2"
      :class="button.class"
      :data-testid="button.testId"
      :disabled="disabled"
      type="button"
      @click="button.handler"
   >
      {{ button.label }}
   </button>
</template>

<!-- This has to have the full <script></script> tags. Otherwise, the linter freaks out. -->
<script lang="ts" src="./ActionButton.ts"></script>

<!-- Add "scoped" attribute to limit the SCSS to this component only. -->
<style lang="scss" scoped src="./ActionButton.scss" />
