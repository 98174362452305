/* ========================================================================== */
// ALL REQUIRED IMPORTS
/* ========================================================================== */
// Vue
// Packages
// Context / Stores / Routers
// Components / Classes / Controllers / Services
// Assets
// Constants / Models / Interfaces / Types
import type { ActionButtonLabels } from '@/constants/global';
import type { Maybe, VoidFunction } from '@types/global.d';
// Utils / Methods / Mocks / Decorators
// Styles

/* ========================================================================== */
// INTERNAL HELPERS, INTERFACES, VARS & SET UP
/* ========================================================================== */
/* ========================================================================== */
// DEFINING THE GLOBAL COMPONENT DATA MODELS
/* ========================================================================== */
export interface ActionButton {
   class: string;
   handler: VoidFunction;
   id: string;
   label: ActionButtonLabels;
   testId: string;
}

export enum ParamsError {
   AccessDenied = 'access_denied',
}

export interface SpotifySearchParams {
   code?: string;
   error?: ParamsError;
   state?: string;
}

export interface Track {
   album: {
      name: string;
   };
   artists: any[]; // TODO :: update this to have a proper shape
   available_markets: any[]; // TODO :: update this to have a proper shape
   disc_number: number;
   duration_ms: number;
   external_ids: Record<string, unknown>;
   external_urls: Record<string, unknown>;
   hasBeenSelected?: Maybe<boolean>;
   href: string;
   id: string;
   is_local: boolean;
   name: string;
   popularity: number;
   preview_url?: Maybe<string>;
   track_number: number;
   type: string; // TODO :: convert to enum with 'track' and other options?
   uri: string; // TODO :: test using the specific string shape thing, I forget the name, but find it
}
