/* ========================================================================== */
// ALL REQUIRED IMPORTS
/* ========================================================================== */
// Vue
import { defineComponent } from 'vue';
// Packages
import { isEmpty } from 'lodash';
import chalk, { ChalkInstance } from 'chalk';
import classNames from 'classnames';
import queryString, { ParsedQuery } from 'query-string';
// Context / Store / Router
import { useAppStore } from '@stores/app.store';
import { useSearchStore } from '@stores/search.store';
// Components / Classes / Controllers / Services
import HeaderNav from '@comps/HeaderNav';
import SearchBar from '@comps/SearchBar';
import SearchResults from '@comps/SearchResults';
import UserPlaylist from '@comps/UserPlaylist';
// Assets
// Constants / Models / Interfaces / Types
import {
   REFRESH_TOKEN_MESSAGE,
   SPM_LOG_ASCII_ART,
   SPM_LOG_DISCLAIMER,
   StorageKeys,
} from '@/constants/global';
import type { AppData } from '../../@types/components/data.d'; // '@types/components/data.d';
import type { SpotifySearchParams } from '../../@types/components/models.d'; // '@types/components/data.d';
import type { UserInfo, UserPlaylistInfo } from '@/constants/global';
// Utils / Methods / Mocks
import { getSpmBaseUrl, isSpotifyCallbackPresent } from '@utils/general';
import { spotify } from '@utils/Spotify';
// Styles

/* ========================================================================== */
// INTERNAL HELPERS, INTERFACES, VARS & SET UP
/* ========================================================================== */
const debugChalk: ChalkInstance = chalk.blue.italic;
const errorChalk: ChalkInstance = chalk.black.bgRed;
const infoChalk: ChalkInstance = chalk.black.bgCyan;
const successChalk: ChalkInstance = chalk.black.bgGreen;
const warnChalk: ChalkInstance = chalk.black.bgYellow;

/* ========================================================================== */
// DEFINING THE `MAIN APP` COMPONENT
/* ========================================================================== */
export default defineComponent({
   // ——————————————————————————————————————————
   // All lifecycle methods/options are listed for reference, delete those are not needed and/or unused
   // ——————————————————————————————————————————
   name: 'App',
   components: {
      HeaderNav,
      SearchBar,
      SearchResults,
      UserPlaylist,
   },
   setup() {
      // you can always use `mapStores` from `pinia` (in the `computed` section) if needed
      const appStore = useAppStore();
      const searchStore = useSearchStore();

      return {
         appStore,
         searchStore,
      };
   },
   data(): AppData {
      return {
         isLoading: false,
         message: '',
      };
   },
   beforeMount(): void {
      // NOTE **[G]** :: How do I want to handle if/when the update their user info on Spotify?
      const storedUserInfo: string | null = localStorage.getItem(StorageKeys.UserInfo);

      if (storedUserInfo) {
         this.appStore.setUserInfo(JSON.parse(storedUserInfo));
      }
   },
   beforeUnmount(): void {
      window.removeEventListener('load', this.handleWindowLoad);
   },
   mounted(): void {
      // #6c41ec = $spm-purple
      console.log(chalk.hex('#6c41ec')(SPM_LOG_ASCII_ART), SPM_LOG_DISCLAIMER);

      window.addEventListener('load', this.handleWindowLoad);
      window.addEventListener('resize', this.handleResize);
      this.handleResize();
   },
   unmounted(): void {
      window.removeEventListener('resize', this.handleResize);
   },
   methods: {
      getMainSectionClasses(): string {
         const { isMobile, showPermissionsError } = this.appStore;
         const { showSearchError } = this.searchStore;

         return classNames({
            'flex flex-row': !isMobile,
            'my-16': !showSearchError && !showPermissionsError,
            'mb-16 mt-2': showSearchError || showPermissionsError,
         });
      },
      handleResize(): void {
         // TODO :: Maybe add some logic to detect resizing to portrait and displaying a warning
         // NOTE :: But for now, this is just to detect when we're on a mobile browser
         this.appStore.setIsMobile();
      },
      async handleWindowLoad(/* event */): Promise<void> {
         this.isLoading = true;
         console.debug(debugChalk('[DEBUG] App.handleWindowLoad: A load event has occurred.'));
         // TODO **[G]** :: Do stuff here to show loading spinner/overlay while waiting for info & playlists
         const hasToken: boolean = await spotify.hasPreviousStoredToken();

         if (hasToken) {
            console.debug(
               debugChalk(
                  '[DEBUG] App.handleWindowLoad: Previously stored token found. Verifying user info...',
               ),
            );

            const isVerified: boolean = await this.verifyUserInfo();

            if (isVerified) {
               console.debug(
                  '[DEBUG] App.handleWindowLoad: User is already logged in.',
                  'Refreshing playlists but will not process the event.',
               );

               this.appStore.setIsLoggedIn(true);
               this.refreshUserPlaylists();
            }
         } else if (isSpotifyCallbackPresent()) {
            console.debug(
               debugChalk(
                  '[DEBUG] App.handleWindowLoad: No previous token found, but there is a callback in the load event URL. Processing...',
               ),
            );

            await this.processWindowLoadEvent();
         } else {
            console.debug(
               debugChalk(
                  '[DEBUG] App.handleWindowLoad: There is no previously stored token and no Spotify callback is present. Doing nothing.',
               ),
            );
         }

         this.isLoading = false;
      },
      // TODO **[G]** :: should prolly move this to the `appStore` to be more global
      // or maybe/prolly use the `spotify.logout` util method/helper to do things
      logout(): void {
         // TODO **[G]** :: In the future, show an "oops, something went wrong. contact support" message in the banner?
         this.appStore.setIsLoggedIn(false);
         this.searchStore.setShowSearchError(false);
         // TODO **[G]** :: Update this error to be a more general `showLoginError`
         this.appStore.setShowPermissionsError(true);
      },
      async processWindowLoadEvent(): Promise<void> {
         try {
            const { search } = window.location;
            const params = queryString.parse(search) as ParsedQuery<SpotifySearchParams>;

            if (!params.code) {
               throw Error('Spotify params.code is missing. Cannot log the user in.');
            }

            const isPermissionGranted: boolean = spotify.processCallback();

            if (isPermissionGranted) {
               // NOTE **[G]** :: What kind of security can/do/want I need to do to prevent somebody else from using the params.code maliciously?
               // TODO **[G]** :: See about using the state key, storing it in the store, and passing it around for use.
               // This is so I can hide it and not allow it to be used with the params.code
               const isGetAccessTokenSuccessful: boolean = await spotify.getAccessToken(
                  params.code as string,
               );

               if (isGetAccessTokenSuccessful) {
                  const userInfo: UserInfo = await spotify.getUserInfo();
                  this.appStore.setUserInfo(userInfo);
                  localStorage.setItem(StorageKeys.UserInfo, JSON.stringify(userInfo));
                  this.appStore.setIsLoggedIn(true);
                  this.refreshUserPlaylists();
                  this.searchStore.setShowSearchError(false);
                  this.appStore.setShowPermissionsError(false);
               } else {
                  console.warn(
                     warnChalk(
                        `[WARN] App.processWindowLoadEvent: The access token did not seem to get stored.`,
                     ),
                  );

                  this.logout();
               }
            } else {
               console.warn(
                  warnChalk(
                     `[WARN] App.processWindowLoadEvent: App/User permissions were not granted.`,
                  ),
               );

               this.logout();
            }
         } catch (error) {
            console.error(
               errorChalk(
                  'App.handleWindowLoad: there was a problem processing the Spotify callback and/or getting the access tokens',
               ),
               { error },
            );

            this.logout();
         } finally {
            // TODO **[G]** :: should I only do this if the auth is successful? it might hide info that the user could see to determine what went wrong.
            const hrefLocation: string = getSpmBaseUrl(import.meta.env.PROD);

            console.debug(
               debugChalk(
                  `[DEBUG] App.processWindowLoadEvent: Done handling everything. Setting the window location to ${hrefLocation}...`,
               ),
            );

            window.location.href = hrefLocation;
         }
      },
      async refreshUserPlaylists(): Promise<void> {
         if (!this.appStore.userInfo.userId) {
            console.error(
               'App.refreshUserPlaylists: Cannot refresh user playlists, user ID is missing.',
            );

            return;
         }

         // NOTE **[G]** :: Add some loading spinner behavior for the playlists loading in the sidebar?
         console.debug('App.refreshUserPlaylists: Getting updated user playlists from Spotify.');

         const response: UserPlaylistInfo = await spotify.getUserPlaylists(
            this.appStore.userInfo.userId,
         );

         if (response?.shouldRefreshToken) {
            console.info(`App.refreshUserPlaylists: ${REFRESH_TOKEN_MESSAGE}`);
            await spotify.refreshAccessToken();
         } else if (!isEmpty(response)) {
            this.appStore.setUserPlaylistInfo(response);
         }
      },
      async verifyUserInfo(): Promise<boolean> {
         let isVerified = false;

         if (isEmpty(this.appStore.userInfo)) {
            console.debug(
               debugChalk(
                  '[DEBUG] App.verifyUserInfo: Locally stored info is empty. Calling Spotify API...',
               ),
            );

            try {
               const response: UserInfo = await spotify.getUserInfo();

               if (response?.shouldRefreshToken) {
                  console.info(`App.verifyUserInfo: ${REFRESH_TOKEN_MESSAGE}`);
                  await spotify.refreshAccessToken();
               } else {
                  localStorage.setItem(StorageKeys.UserInfo, JSON.stringify(response));
                  this.appStore.setUserInfo(response);
                  isVerified = true;
               }
            } catch (err) {
               const error = err as Error;
               console.error('[ERROR] There was a problem verifying the user info.', { error });
            }
         } else {
            console.debug(
               debugChalk(
                  '[DEBUG] App.verifyUserInfo: Locally app stored info found. Verifying...',
               ),
            );

            const expectedKeys: string[] = ['name', 'profileImgUrl', 'userId'];

            isVerified = Object.entries(this.appStore.userInfo).every(
               ([userInfoKey, userInfoValue], idx) => {
                  const isMatchingKeys: boolean = userInfoKey === expectedKeys[idx];
                  const isMatchingTypes: boolean = typeof userInfoValue === 'string';
                  return isMatchingKeys && isMatchingTypes;
               },
            );
         }

         return isVerified;
      },
   },
});
