<template>
   <section class="user-playlist-wrapper">
      <div
         class="user-playlist-container"
         :class="getUserPlaylistContainerClasses()"
         data-testid="$user-playlist-container"
      >
         <div class="flex flex-row justify-between">
            <div :class="getUserPlaylistTitleClasses()">
               <h1 :class="getUserPlaylistHeaderClasses()">My Playlist</h1>
               <input
                  class="user-playlist-name-input"
                  :class="getUserPlaylistNameInputClasses()"
                  name="User Playlist Name"
                  placeholder="Enter a playlist name..."
                  type="text"
                  :value="playlistStore.playlistName"
                  @input="handleInput"
               />
            </div>
            <p v-if="appStore.isMobile" class="cursor-pointer select-none" @click="navigate()">
               results &gt;
            </p>
         </div>
         <div class="user-playlist-list" :class="getUserPlaylistListClasses()">
            <div data-testid="$user-playlist-empty">
               <p v-if="!playlistStore.selectedTracks.length" class="call-to-action">
                  Select some tracks to start your playlist.
               </p>
               <p
                  v-if="playlistStore.selectedTracks.length && !playlistStore.playlistName.length"
                  class="alert alert-info"
               >
                  You need a name to save the playlist.
               </p>
            </div>
            <!--
            NOTE :: Do I want to add tracks pagination?
            If so, how do I want to handle it?
            -->
            <TrackInfo
               v-for="track in playlistStore.selectedTracks"
               :key="track.id"
               :track="track"
            />
         </div>
      </div>
      <div class="user-playlist-action-buttons md:ml-6 mt-4">
         <ActionButton
            v-for="button in actionButtons"
            :key="button.id"
            :button="button"
            :disabled="isButtonDisabled(button.label)"
         />
      </div>
   </section>
</template>

<!-- This has to have the full <script></script> tags. Otherwise, the linter freaks out. -->
<script lang="ts" src="./UserPlaylist.ts"></script>

<!-- Add "scoped" attribute to limit the SCSS to this component only. -->
<style lang="scss" scoped src="./UserPlaylist.scss" />
