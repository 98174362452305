/* ========================================================================== */
// ALL REQUIRED IMPORTS
/* ========================================================================== */
// Vue
// Packages
import { _GettersTree, defineStore } from 'pinia';
// Context / Stores / Routers
// Components / Classes / Controllers / Services
// Assets
// Constants / Models / Interfaces / Types
import type { SearchStoreActions, SearchStoreState } from '../../@types/stores.d'; // '@types/stores.d';
import type { Track } from '../../@types/components/models.d';
// Utils / Methods / Mocks / Decorators
import { generateFakeTracks, sortTracks } from '@utils/general';
// Styles

/* ========================================================================== */
// INTERNAL HELPERS, INTERFACES, VARS & SET UP
/* ========================================================================== */
/* ========================================================================== */
// DEFINING THE `SEARCH MODULE` STORE
/* ========================================================================== */
export const useSearchStore = defineStore<
   string,
   SearchStoreState,
   _GettersTree<SearchStoreState>,
   SearchStoreActions
>('search', {
   // arrow functions are recommended for `state` to get full type inference
   state: (): SearchStoreState => {
      return {
         permissionsErrorMessage: 'You must accept the Spotify permissions to use this app',
         searchErrorMessage: '',
         searchResults: [], // TODO **[G]** :: Create a `localSession` backup that this will check/pull from first after a hard refresh
         searchTerm: '',
         showNoResultsMessage: false, // this should only be set to `true` when there is a valid `searchTerm` present and the API responded with nothing
         showSearchError: false,
      };
   },
   actions: {
      addTrack(track: Track): void {
         this.searchResults.push(track);

         if (this.searchResults.length > 1) {
            this.searchResults = sortTracks(this.searchResults);
         }
      },
      clearSearchResults(): void {
         this.searchResults = [];
      },
      clearSearchTerm(): void {
         this.searchTerm = '';
      },
      removeTrack(track: Track): void {
         this.searchResults = this.searchResults.filter((result: Track) => {
            return result.name !== track.name;
         });

         if (this.searchResults.length > 1) {
            this.searchResults = sortTracks(this.searchResults);
         }
      },
      setSearchErrorMessage(message: string): void {
         this.searchErrorMessage = message;
      },
      setSearchResults(searchResults: Track[]): void {
         this.searchResults = searchResults || generateFakeTracks(10); // TODO **[G]** :: Remove the fake tracks util once I'm wired up

         // TODO **[G]** :: In a later phase, add the ability to sort by artist name & other stuffs too
         this.searchResults.sort((a: Track, b: Track) => {
            return a.name.localeCompare(b.name);
         });
      },
      setSearchTerm(searchTerm: string): void {
         this.searchTerm = searchTerm;
      },
      setShowNoResultsMessage(showNoResultsMessage: boolean): void {
         this.showNoResultsMessage = showNoResultsMessage;
      },
      setShowSearchError(showSearchError: boolean): void {
         this.showSearchError = showSearchError;
      },
   },
   getters: {},
});
