/* ========================================================================== */
// ALL REQUIRED IMPORTS
/* ========================================================================== */
// Vue
import { createApp } from 'vue';
// Packages
// Context / Stores / Routers
import { createPinia } from 'pinia';
// Components / Classes / Controllers / Services
import App from './App';
// Assets
// Constants / Models / Interfaces / Types
// Utils / Methods / Mocks / Decorators
// Styles
import '../styles/index.scss';

/* ========================================================================== */
// DEFINING THE `MAIN ROOT APP INSTANCES`
/* ========================================================================== */
const pinia = createPinia();
const app = createApp(App);

// NOTE :: To use or not to use? That is the question.
// Vue 3 version of "Vue.config.productionTip = false;"
// app.config.warnHandler = (msg, vm, trace) => {
//    return null;
// };

app.use(pinia);
app.mount('#app');
