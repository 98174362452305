<template>
   <header
      class="header-nav-container flex items-center justify-between p-4"
      data-testid="$header-nav-container"
   >
      <h1 class="font-bold text-2xl">
         <a href="/" target="_self">{{ title }}</a>
      </h1>
      <nav class="header-nav-auth flex items-center" data-testid="$header-nav-auth">
         <div
            v-if="appStore.isLoggedIn"
            class="header-nav-user pr-2"
            data-testid="$header-nav-user"
         >
            <p v-if="!appStore.isMobile" data-testid="$header-nav-user-name">
               Welcome,
               <span class="header-nav-greeting"> {{ appStore.userInfo.name }} </span>!
            </p>
            <i v-if="appStore.isMobile" class="cursor-pointer" data-testid="$header-nav-user-icon">
               <UserIcon class="h-6" />
            </i>
         </div>
         <button
            class="spotify-auth-btn py-1.5"
            :class="appStore.isLoggedIn ? 'px-4' : appStore.isMobile ? 'px-5' : 'px-8'"
            data-testid="$header-nav-auth-btn"
            type="button"
            @click="handleClick"
         >
            <p v-if="!appStore.isLoggedIn" class="h-6">log in</p>
            <i v-else data-testid="$header-nav-menu">
               <MenuIcon class="h-6" />
            </i>
         </button>
      </nav>
   </header>
</template>

<!-- This has to have the full <script></script> tags. Otherwise, the linter freaks out. -->
<script lang="ts" src="./HeaderNav.ts"></script>

<!-- Add "scoped" attribute to limit the SCSS to this component only. -->
<style lang="scss" scoped src="./HeaderNav.scss"></style>
