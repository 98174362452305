/* ========================================================================== */
// ALL REQUIRED IMPORTS
/* ========================================================================== */
// Vue
import { defineComponent } from 'vue';
// Packages
import { XMarkIcon } from '@heroicons/vue/20/solid';
import classNames from 'classnames';
import lodash from 'lodash';
// Context / Store / Router
import { useAppStore } from '@stores/app.store';
import { usePlaylistStore } from '@stores/playlist.store';
import { useSearchStore } from '@stores/search.store';
// Components / Classes / Controllers / Services
import ActionButton from '@comps/ActionButton';
// Assets
// Constants / Models / Interfaces / Types
import {
   ActionButtonLabels,
   REFRESH_TOKEN_MESSAGE,
   SEARCH_TERM_MIN_LENGTH,
} from '@/constants/global';
import { CurrentMobileNavLocation } from '../../../@types/stores.d';
import type { SearchBarData } from '../../../@types/components/data.d'; // '@types/components/data.d';
import type { SearchResults } from '@/constants/global';
import type { VoidFunction } from '../../../@types/global';
// Utils / Methods / Mocks
import { spotify } from '@utils/Spotify';
// Styles

/* ========================================================================== */
// INTERNAL HELPERS, INTERFACES, VARS & SET UP
/* ========================================================================== */
/* ========================================================================== */
// DEFINING THE 'SEARCH BAR' COMPONENT
/* ========================================================================== */
export default defineComponent({
   name: 'SearchBar',
   components: {
      ActionButton,
      XMarkIcon,
   },
   setup() {
      const appStore = useAppStore();
      const playlistStore = usePlaylistStore();
      const searchStore = useSearchStore();

      return {
         appStore,
         playlistStore,
         searchStore,
      };
   },
   data(): SearchBarData {
      return {
         actionButtons: [
            // Keep these in the order that you want them displayed
            {
               class: 'search-bar-submit mr-6',
               handler: this.handleSubmit as VoidFunction,
               id: lodash.uniqueId('action-button-'),
               label: ActionButtonLabels.Search,
               testId: '$search-bar-submit',
            },
            {
               class: 'search-bar-clear-all ml-6',
               handler: this.handleClearAll as VoidFunction,
               id: lodash.uniqueId('action-button-'),
               label: ActionButtonLabels.ClearAll,
               testId: '$search-bar-clear-all',
            },
         ],
      };
   },
   computed: {
      placeholder(): string {
         return this.appStore.isMobile
            ? 'Enter a song or artist name...'
            : 'Enter a song, album, or artist name...';
      },
   },
   methods: {
      getSearchBarErrorClasses(): string {
         return classNames('flex justify-center mt-1.5 w-full');
      },
      getSearchBarInputClasses(): string {
         return classNames('py-4 w-full', {
            'pl-5 text-left': this.appStore.isMobile,
         });
      },
      getSearchBarInputClearClasses(): string {
         return classNames({
            'mr-2': this.appStore.isMobile,
         });
      },
      getSearchBarWrapperClasses(): string {
         return classNames('flex items-center justify-center mt-8', {
            'w-1/2': !this.appStore.isMobile,
            'w-11/12': this.appStore.isMobile,
         });
      },
      handleClearAll(): void {
         this.playlistStore.clearPlaylist();
         this.searchStore.setShowNoResultsMessage(false);
         this.searchStore.setSearchErrorMessage('');
         this.searchStore.setShowSearchError(false);
         this.searchStore.clearSearchResults();
         this.searchStore.clearSearchTerm();
         this.appStore.setCurrentMobileNavLocation('search-results');
      },
      handleClearInput(): void {
         this.searchStore.setSearchErrorMessage('');
         this.searchStore.setShowSearchError(false);
         this.searchStore.clearSearchTerm();
      },
      handleInputBlur(): void {
         const inputWrapper = document.querySelector('.search-bar-input-wrapper');

         if (inputWrapper) {
            inputWrapper.className = inputWrapper.className.replace(' focused', '');
         }
      },
      handleInput(event: Event): void {
         const target = event.target as HTMLInputElement;
         this.searchStore.setSearchTerm(target.value);

         const shouldClearSearchError: boolean =
            this.searchStore.showSearchError &&
            this.searchStore.searchTerm?.length >= SEARCH_TERM_MIN_LENGTH;

         if (shouldClearSearchError) {
            this.searchStore.setShowSearchError(false);
         }
      },
      handleInputFocus(): void {
         const inputWrapper = document.querySelector('.search-bar-input-wrapper');

         if (inputWrapper) {
            inputWrapper.className += ' focused';
         }
      },
      async handleSubmit(): Promise<void> {
         if (!this.appStore.isLoggedIn) {
            this.searchStore.setSearchErrorMessage('You must be logged in to search');
            this.searchStore.setShowSearchError(true);
            return;
         }

         if (this.searchStore.searchTerm?.length >= SEARCH_TERM_MIN_LENGTH) {
            this.searchStore.setSearchErrorMessage('');
            this.searchStore.setShowSearchError(false);

            const response: SearchResults | undefined = await spotify.search(
               this.searchStore.searchTerm,
            );

            if (response?.shouldRefreshToken) {
               console.info(`SearchBar.handleSubmit: ${REFRESH_TOKEN_MESSAGE}`);
               await spotify.refreshAccessToken();
            } else if (response?.tracks?.items?.length) {
               this.searchStore.setShowNoResultsMessage(false);
               this.searchStore.setSearchResults(response.tracks.items);

               if (this.appStore.isMobile) {
                  this.appStore.setCurrentMobileNavLocation(CurrentMobileNavLocation.SearchResults);
               }
            } else if (response?.tracks?.items?.length === 0) {
               this.searchStore.setShowNoResultsMessage(true);
            } else {
               // a response was not returned which likely means the user is no longer authorized
               this.searchStore.setSearchErrorMessage('You must be logged in to search');
               this.searchStore.setShowSearchError(true);
               this.appStore.setIsLoggedIn(false);
               this.appStore.setUserInfo({});
            }
         } else {
            if (
               this.searchStore.searchTerm?.length > 0 &&
               this.searchStore.searchTerm?.length < SEARCH_TERM_MIN_LENGTH
            ) {
               this.searchStore.setSearchErrorMessage(
                  `You need at least ${SEARCH_TERM_MIN_LENGTH} letters to search`,
               );
            }

            if (this.searchStore.searchTerm?.length === 0) {
               this.searchStore.setSearchErrorMessage('A search term is required');
            }

            this.searchStore.setShowSearchError(true);
         }
      },
   },
});
