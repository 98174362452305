/* ========================================================================== */
// ALL REQUIRED IMPORTS
/* ========================================================================== */
// Vue
// Packages
import { _GettersTree, defineStore } from 'pinia';
// Context / Stores / Routers
// Components / Classes / Controllers / Services
// Assets
// Constants / Models / Interfaces / Types
import { AppStoreActions, AppStoreState, CurrentMobileNavLocation } from '../../@types/stores.d'; // '@types/stores.d';
import type { UserInfo, UserPlaylistInfo } from '@/constants/global';
// Utils / Methods / Mocks / Decorators
// Styles

/* ========================================================================== */
// INTERNAL HELPERS, INTERFACES, VARS & SET UP
/* ========================================================================== */
/* ========================================================================== */
// DEFINING THE `APP MODULE` STORE
/* ========================================================================== */
export const useAppStore = defineStore<
   string,
   AppStoreState,
   _GettersTree<AppStoreState>,
   AppStoreActions
>('app', {
   // arrow functions are recommended for `state` to get full type inference
   state: (): AppStoreState => {
      return {
         currentMobileNavLocation: undefined,
         isLoggedIn: false,
         isMobile: true,
         showPermissionsError: false,
         userInfo: {} as UserInfo,
         userPlaylistInfo: {} as UserPlaylistInfo,
      };
   },
   actions: {
      setCurrentMobileNavLocation(location: string): void {
         this.currentMobileNavLocation = location as CurrentMobileNavLocation;
      },
      setIsLoggedIn(isLoggedIn: boolean): void {
         this.isLoggedIn = isLoggedIn;
      },
      setIsMobile(): void {
         const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
            navigator.userAgent,
         );

         const isPortrait: boolean = window.innerHeight / window.innerWidth > 1;
         this.isMobile = isMobile || isPortrait;

         if (this.isMobile) {
            this.currentMobileNavLocation = CurrentMobileNavLocation.SearchResults;
         }
      },
      setShowPermissionsError(showWarning: boolean): void {
         this.showPermissionsError = showWarning;
      },
      setUserInfo(userInfo: UserInfo): void {
         this.userInfo = userInfo;
      },
      setUserPlaylistInfo(userPlaylistInfo: UserPlaylistInfo): void {
         this.userPlaylistInfo = userPlaylistInfo;
      },
   },
   getters: {},
});
