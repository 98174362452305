/* ========================================================================== */
// ALL REQUIRED IMPORTS
/* ========================================================================== */
// Vue
import { defineComponent, PropType } from 'vue';
// Packages
// Context / Store / Router
// import { useAppStore } from '@stores/app.store';
// import { usePlaylistStore } from '@stores/playlist.store';
// import { useSearchStore } from '@stores/search.store';
// Components / Classes / Controllers / Services
// Assets
// Constants / Models / Interfaces / Types
import type { ActionButton } from '../../../@types/components/models.d';
// Utils / Methods / Mocks
// Styles

/* ========================================================================== */
// INTERNAL HELPERS, INTERFACES, VARS & SET UP
/* ========================================================================== */
interface ActionButtonData {
   // add type defs here...
   componentName: string;
}

/* ========================================================================== */
// DEFINING THE 'ActionButton' COMPONENT
/* ========================================================================== */
export default defineComponent({
   name: 'ActionButton',
   components: {},
   props: {
      button: {
         required: true,
         type: Object as PropType<ActionButton>,
      },
      disabled: {
         default: false,
         required: false,
         type: Boolean,
      },
   },
   setup() {
      // const appStore = useAppStore();
      // const playlistStore = usePlaylistStore();
      // const searchStore = useSearchStore();
      // return {
      //    appStore,
      //    playlistStore,
      //    searchStore,
      // };
   },
   data(): ActionButtonData {
      return {
         componentName: 'ActionButton',
      };
   },
   computed: {},
   watch: {},
   beforeCreate(): void {},
   beforeMount(): void {},
   beforeUnmount(): void {},
   beforeUpdate(): void {},
   created(): void {},
   mounted(): void {},
   unmounted(): void {},
   updated(): void {},
   methods: {},
});
