// Vue
// Packages
// Context / Stores / Routers
// Components / Classes / Controllers / Services
// Assets
// Constants / Models / Interfaces / Types
import type { ReasonPhrases, StatusCodes } from 'http-status-codes';
import type { Track } from '../../@types/components/models.d';
// Utils / Methods / Mocks / Decorators
// Styles

// export const ACCESS_TOKEN_BASE_URL = 'https://accounts.spotify.com/api/token';
export const REFRESH_TOKEN_MESSAGE =
   'Authorization seems to have expired. Attempting to refresh...';
export const SEARCH_TERM_MIN_LENGTH = 2;
export const SPM_API_AUTH_COOKIE_NAME = 'spm_api_auth';
export const COOKIE_STATE_KEY = 'spotify_auth_state'; // TODO :: rename to SPM_AUTH_STATE
export const SPM_KEYTAR_ACCOUNT_NAME = 'spm_auth_payload';
export const SPM_KEYTAR_SERVICE_NAME = 'spm_api_auth';
export const SPOTIFY_API_BASE_URL = 'https://api.spotify.com/v1';
export const SPOTIFY_AUTH_BASE_URL = 'https://accounts.spotify.com';
export const SPOTIFY_CONTENT_TYPE_HEADER = 'application/x-www-form-urlencoded';

export const SPM_LOG_ASCII_ART = `
      *******            ***** **         *****   **    **
    *       ***       ******  ****     ******  ***** *****
   *         **      **   *  *  ***   **   *  *  ***** *****
   **        *      *    *  *    *** *    *  *   * **  * **
    ***                 *  *      **     *  *    *     *
   ** ***              ** **      **    ** **    *     *
    *** ***            ** **      **    ** **    *     *
      *** ***        **** **      *     ** **    *     *
        *** ***     * *** **     *      ** **    *     *
          ** ***       ** *******       ** **    *     **
           ** **       ** ******        *  **    *     **
            * *        ** **               *     *      **
  ***        *         ** **           ****      *      **
 *  *********          ** **          *  *****           **
*     *****       **   ** **         *     **
*                ***   *  *          *
 **               ***    *            **
                   ******
                     ***
`;

export const SPM_LOG_DISCLAIMER = `
Welcome to Spotify Playlist Maker!

If you're seeing this then you acknowledge that you know what you're doing
and that you assume all responsibilities for interacting with the console.

🙂 GLHF!`;

interface SpotifyAuth {
   shouldRefreshToken?: boolean;
}

// TODO :: Move these enums to a better place/file/location
export enum ActionButtonLabels {
   ClearAll = 'Clear All',
   ClearPlaylist = 'Clear Playlist',
   ClearResults = 'Clear Results',
   SavePlaylist = 'Save Playlist',
   Search = 'Search',
   TestButton = 'Test Button',
}

export enum NetlifyAction {
   CheckForStoredKey = 'check-for-stored-key',
   RefreshAccessToken = 'refresh-access-token',
}

export interface PlaylistCollection extends SpotifyAuth {
   playlistName?: string;
   tracks?: Track[];
}

export enum PlaylistScopes {
   PlaylistModifyPrivate = 'playlist-modify-private',
   PlaylistModifyPublic = 'playlist-modify-public',
   // PlaylistReadCollaborative = 'playlist-read-collaborative',
   PlaylistReadPrivate = 'playlist-read-private',
   Streaming = 'streaming',
   UserReadEmail = 'user-read-email',
}

export interface SavePlaylistResponse extends SpotifyAuth {
   isSaved: boolean;
}

export interface SearchResponse {
   body: string;
   message: ReasonPhrases;
   statusCode: StatusCodes;
   statusText?: ReasonPhrases;
}

export interface SearchResults extends SpotifyAuth {
   message?: string;
   tracks?: TracksInfo;
}

export interface SpotifyFollowers {
   href?: string;
   total?: number;
}

export enum SpotifyGrantType {
   AuthorizationCode = 'authorization_code',
   RefreshToken = 'refresh_token',
}

export interface SpotifyImage {
   height?: number;
   url: string;
   width?: number;
}

export interface SpotifyOwner {
   display_name: string;
   external_urls: SpotifyUrls;
   followers: SpotifyFollowers;
   href: string;
   id: string;
   type: SpotifyTypes;
   uri: string;
}

export interface SpotifyPlaylistInfo {
   href: string;
   items: UserPlaylist[];
   limit: number;
   next?: string;
   offset: number;
   previous?: string;
   total: number;
}

export interface SpotifyTracksInfo {
   href: string;
   total: number;
}

export enum SpotifyTypes {
   Playlist = 'playlist',
   User = 'user',
}

export interface SpotifyUrls {
   spotify: string;
}

export interface SpotifyUserInfo {
   country?: string;
   display_name: string;
   email: string;
   explicit_content?: {
      filter_enabled: boolean;
      filter_locked: boolean;
   };
   external_urls: SpotifyUrls;
   followers: SpotifyFollowers;
   href: string;
   id: string;
   images: SpotifyImage[];
   product?: string;
   type: string;
   uri: string;
}

export enum StorageKeys {
   UserInfo = 'user-info',
}

interface TracksInfo {
   href: string;
   items: Track[];
   limit: number;
   next: string;
   offset: number;
   previous: string;
   total: number;
}

export interface UserAuth {
   accessToken?: string;
   refreshToken?: string;
}

export interface UserInfo extends SpotifyAuth {
   name?: string;
   profileImgUrl?: string;
   userId?: string;
}

export interface UserPlaylist {
   collaborative: boolean;
   description: string;
   external_urls: SpotifyUrls;
   followers?: SpotifyFollowers;
   href: string;
   id: string;
   images: SpotifyImage[];
   name: string;
   owner: SpotifyOwner;
   primary_color?: string;
   public: boolean;
   snapshot_id: string;
   tracks: SpotifyTracksInfo;
   type: SpotifyTypes;
   uri: string;
}

export interface UserPlaylistInfo extends SpotifyAuth {
   numPlaylists?: number;
   playlists?: UserPlaylist[];
}
