/* ========================================================================== */
// ALL REQUIRED IMPORTS
/* ========================================================================== */
// Vue
import { defineComponent, PropType } from 'vue';
// Packages
import { MinusCircleIcon, PlayIcon, PlusCircleIcon } from '@heroicons/vue/20/solid';
import classNames from 'classnames';
// Context / Store / Router
import { useAppStore } from '@stores/app.store';
import { usePlaylistStore } from '@stores/playlist.store';
import { useSearchStore } from '@stores/search.store';
// Components / Classes / Controllers / Services
// Assets
// Constants / Models / Interfaces / Types
import type { Track } from '../../../@types/components/models.d';
import type { TrackInfoData } from '../../../@types/components/data.d';
// Utils / Methods / Mocks
// Styles

/* ========================================================================== */
// INTERNAL HELPERS, INTERFACES, VARS & SET UP
/* ========================================================================== */
/* ========================================================================== */
// DEFINING THE 'TRACK INFO' COMPONENT
/* ========================================================================== */
export default defineComponent({
   name: 'TrackInfo',
   components: {
      MinusCircleIcon,
      PlayIcon,
      PlusCircleIcon,
   },
   props: {
      track: {
         required: true,
         type: Object as PropType<Track>,
      },
   },
   setup() {
      const appStore = useAppStore();
      const playlistStore = usePlaylistStore();
      const searchStore = useSearchStore();

      return {
         appStore,
         playlistStore,
         searchStore,
      };
   },
   data(): TrackInfoData {
      return {
         componentName: 'TrackInfo',
      };
   },
   methods: {
      addPlaylistTrack(): void {
         if (this.playlistContainsTrack()) {
            this.searchStore.setSearchErrorMessage('That song is already in the playlist.');
            this.searchStore.setShowSearchError(true);
            return;
         }

         this.searchStore.setShowSearchError(false);
         this.playlistStore.addTrack(this.track);
         this.searchStore.removeTrack(this.track);
      },
      getTrackInfoClasses(): string {
         return classNames({
            'flex flex-row justify-between': !this.appStore.isMobile,
            'text-left': this.appStore.isMobile,
         });
      },
      getTrackInfoContainerClasses(): string {
         return classNames('my-2 p-4');
      },
      getTrackInfoNameClasses(): string {
         return classNames('mb-4 mx-4');
      },
      playlistContainsTrack(): boolean {
         const hasTrack: boolean = this.playlistStore.containsTrack(this.track);
         return hasTrack;
      },
      previewTrack(): void {
         // TODO :: If possible, check to see if the current user has a premium account
         // If they do, play/stream the track (for 30s? 60s? full song?)
         // If they don't, display an alert letting them know they need a premium account
         console.debug(`Previewing track: "${this.track.name}".`);
      },
      removePlaylistTrack(): void {
         this.playlistStore.removeTrack(this.track);
         this.searchStore.addTrack(this.track);
      },
   },
});
