<template>
   <section class="app-container flex flex-col min-h-screen" data-testid="$app-container">
      <HeaderNav />
      <SearchBar />
      <main class="self-center w-11/12" :class="getMainSectionClasses()">
         <SearchResults
            v-if="!appStore.isMobile || appStore.currentMobileNavLocation === 'search-results'"
         />
         <UserPlaylist
            v-if="!appStore.isMobile || appStore.currentMobileNavLocation === 'user-playlist'"
         />
      </main>
   </section>
</template>

<!-- This has to have the full <script></script> tags. Otherwise, the linter freaks out -->
<script lang="ts" src="./App.ts"></script>

<!-- Add "scoped" attribute to limit the SCSS to this component only -->
<style lang="sass" scoped src="./App.scss"></style>
