/* ========================================================================== */
// ALL REQUIRED IMPORTS
/* ========================================================================== */
// Vue
import { defineComponent } from 'vue';
// Packages
import classNames from 'classnames';
import lodash from 'lodash';
// Context / Store / Router
import { useAppStore } from '@stores/app.store';
import { useSearchStore } from '@stores/search.store';
// Components / Classes / Controllers / Services
import ActionButton from '@comps/ActionButton';
import TrackInfo from '@comps/TrackInfo';
// Assets
// Constants / Models / Interfaces / Types
import { ActionButtonLabels } from '@/constants/global';
import type { SearchResultsData } from '../../../@types/components/data.d';
import type { VoidFunction } from '../../../@types/global.d';
// Utils / Methods / Mocks
// Styles

/* ========================================================================== */
// INTERNAL HELPERS, INTERFACES, VARS & SET UP
/* ========================================================================== */
/* ========================================================================== */
// DEFINING THE 'SEARCH RESULTS' COMPONENT
/* ========================================================================== */
export default defineComponent({
   name: 'SearchResults',
   components: {
      ActionButton,
      TrackInfo,
   },
   setup() {
      const appStore = useAppStore();
      const searchStore = useSearchStore();

      return {
         appStore,
         searchStore,
      };
   },
   data(): SearchResultsData {
      return {
         actionButtons: [
            {
               class: '',
               handler: this.handleClearResults as VoidFunction,
               id: lodash.uniqueId('action-button-'),
               label: ActionButtonLabels.ClearResults,
               testId: '$search-results-clear',
            },
         ],
         componentName: 'SearchResults',
      };
   },
   methods: {
      getSearchResultsContainerClasses(): string {
         return classNames('flex flex-col p-6', {
            'mr-6': !this.appStore.isMobile,
         });
      },
      getSearchResultsHeaderClasses(): string {
         return classNames('font-normal self-start underline');
      },
      getSearchResultsListClasses(): string {
         return classNames('flex flex-col pt-4');
      },
      handleClearResults(): void {
         this.searchStore.setSearchErrorMessage('');
         this.searchStore.setShowSearchError(false);
         this.searchStore.clearSearchResults();
      },
      // TODO **[G]** :: Convert `isButtonDisabled` to general util?
      isButtonDisabled(): boolean {
         return !this.searchStore.searchResults.length;
      },
      navigate(): void {
         this.appStore.setCurrentMobileNavLocation('user-playlist');
      },
   },
});
