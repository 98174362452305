/* ========================================================================== */
// ALL REQUIRED IMPORTS
/* ========================================================================== */
// Vue
import { defineComponent } from 'vue';
// Packages
import { Bars3Icon as MenuIcon, UserIcon } from '@heroicons/vue/20/solid';
// Context / Stores / Routers
import { useAppStore } from '@stores/app.store';
import { useSearchStore } from '@stores/search.store';
// Components / Classes / Controllers / Services
// Assets
// Constants / Models / Interfaces / Types
import type { HeaderNavData } from '../../../@types/components/data.d'; // '@types/components/data.d';
// Utils / Methods / Mocks / Decorators
import { spotify } from '@utils/Spotify';
// Styles

/* ========================================================================== */
// INTERNAL HELPERS, INTERFACES, VARS & SET UP
/* ========================================================================== */
/* ========================================================================== */
// DEFINING THE 'HEADER NAV' COMPONENT
/* ========================================================================== */
export default defineComponent({
   name: 'HeaderNav',
   components: {
      MenuIcon,
      UserIcon,
   },
   setup() {
      const appStore = useAppStore();
      const searchStore = useSearchStore();

      return {
         appStore,
         searchStore,
      };
   },
   data(): HeaderNavData {
      return {
         title: 'Spotify Playlist Maker',
      };
   },
   methods: {
      async handleClick(): Promise<void> {
         if (!this.appStore.isLoggedIn) {
            await spotify.login();
         } else {
            console.debug('Clicking the menu button will eventually show a slide out drawer.');
            // TODO **[G]** :: Reminder that there will be a "log out" option that will delete the stored tokens, cookie, and URL code info
         }
      },
   },
});
