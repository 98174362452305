<template>
   <section
      class="search-bar-container flex flex-col items-center"
      data-testid="$search-bar-container"
   >
      <div class="search-bar-input-wrapper" :class="getSearchBarWrapperClasses()">
         <input
            class="search-bar-input"
            :class="getSearchBarInputClasses()"
            data-testid="$search-bar-input"
            name="search-bar-input"
            :placeholder="placeholder"
            type="text"
            :value="searchStore.searchTerm"
            @blur="handleInputBlur"
            @focus="handleInputFocus"
            @input="handleInput"
            @keyup.enter="handleSubmit"
         />
         <i
            class="search-bar-input-clear cursor-pointer mr-4"
            :class="getSearchBarInputClearClasses()"
            data-testid="$search-bar-input-clear"
            @click="handleClearInput"
         >
            <XMarkIcon class="h-8" />
         </i>
      </div>
      <div class="search-bar-actions mt-6" data-testid="$search-bar-actions">
         <ActionButton v-for="button in actionButtons" :key="button.id" :button="button" />
      </div>
      <div
         v-if="appStore.showPermissionsError || searchStore.showSearchError"
         class="search-bar-error"
         :class="getSearchBarErrorClasses()"
         data-testid="$search-bar-error"
      >
         <!-- TODO **[G]** :: Add an 'oops'/error state to handle API request errors -->
         <p class="alert py-2 w-full" :class="appStore.isLoggedIn ? 'alert-warn' : 'alert-error'">
            {{
               appStore.showPermissionsError
                  ? searchStore.permissionsErrorMessage
                  : searchStore.searchErrorMessage
            }}
         </p>
      </div>
   </section>
</template>

<!-- This has to have the full <script></script> tags. Otherwise, the linter freaks out. -->
<script lang="ts" src="./SearchBar.ts"></script>

<!-- Add "scoped" attribute to limit the SCSS to this component only. -->
<style lang="scss" scoped src="./SearchBar.scss" />
