/* ========================================================================== */
// ALL REQUIRED IMPORTS
/* ========================================================================== */
// Vue
// Packages
// Context / Stores / Routers
// Components / Classes / Controllers / Services
// Assets
// Constants / Models / Interfaces / Types
import type { Store } from 'pinia';
import type { Track } from '@types/components/models.d';
import type { UserInfo, UserPlaylistInfo } from '@/constants/global';
// Utils / Methods / Mocks / Decorators
// Styles

/* ========================================================================== */
// INTERNAL HELPERS, INTERFACES, VARS & SET UP
/* ========================================================================== */
/* ========================================================================== */
// DEFINING THE GLOBAL STORE TYPES
/* ========================================================================== */
export enum CurrentMobileNavLocation {
   SearchResults = 'search-results',
   UserPlaylist = 'user-playlist',
}

/* --------------- [ DEFINING THE `APP STORE` TYPES ] --------------- */
export interface AppStoreActions {
   setCurrentMobileNavLocation(location: string): void;
   setIsLoggedIn(isLoggedIn: boolean): void;
   setIsMobile(): void;
   setShowPermissionsError(showWarning: boolean): void;
   setUserInfo(userInfo: UserInfo): void;
   setUserPlaylistInfo(userPlaylistInfo: UserPlaylistInfo): void;
}

export interface AppStoreState {
   currentMobileNavLocation?: CurrentMobileNavLocation;
   isLoggedIn: boolean;
   isMobile: boolean;
   showPermissionsError: boolean;
   userInfo: UserInfo;
   userPlaylistInfo: UserPlaylistInfo;
}

// NOTE :: 27-Sep-2022 @ 22:50
// NOTE :: This is not actually being used right now. Just keeping it around for reference.
export type AppStore = Store<
   'app',
   AppStoreState,
   Record<string, unknown>, // used to be '{}'
   {
      setCurrentMobileNavLocation(location: string): void;
      setIsLoggedIn(isLoggedIn: boolean): void;
      setIsMobile(): void;
      setShowPermissionsError(showWarning: boolean): void;
      setUserInfo(userInfo: UserInfo): void;
   }
>;

/* --------------- [ DEFINING THE `PLAYLIST STORE` TYPES ] --------------- */
export interface PlaylistStoreActions {
   addTrack(track: Track): void;
   clearPlaylist(): void;
   containsTrack(track: Track): boolean;
   removeTrack(track: Track): void;
   setPlaylistName(playlistName: string): void;
}

export interface PlaylistStoreState {
   playlistName: string;
   selectedTracks: Track[];
}

/* --------------- [ DEFINING THE `SEARCH STORE` TYPES ] --------------- */
export interface SearchStoreActions {
   addTrack(track: Track): void;
   clearSearchResults(): void;
   clearSearchTerm(): void;
   removeTrack(track: Track): void;
   setSearchErrorMessage(message: string): void;
   setSearchResults(searchResults: Track[]): void;
   setSearchTerm(searchTerm: string): void;
   setShowNoResultsMessage(showNoResultsMessage: boolean): void;
   setShowSearchError(showSearchError: boolean): void;
}

export interface SearchStoreState {
   permissionsErrorMessage: string;
   searchErrorMessage: string;
   searchResults: Track[];
   searchTerm: string;
   showNoResultsMessage: boolean;
   showSearchError: boolean;
}
