<template>
   <div
      class="track-info-container"
      :class="getTrackInfoContainerClasses()"
      data-testid="$track-info-container"
   >
      <div class="flex flex-row justify-between">
         <i class="cursor-pointer flex items-center mb-4" title="Preview song">
            <PlayIcon class="h-8" @click="previewTrack()" />
         </i>
         <h2 class="track-info-name" :class="getTrackInfoNameClasses()">
            {{ track.name }}
         </h2>
         <i
            class="cursor-pointer flex items-center mb-4"
            :data-testid="track.hasBeenSelected ? '$icon-remove-track' : '$icon-add-track'"
            :title="track.hasBeenSelected ? 'Remove song' : 'Add song'"
            @click="track.hasBeenSelected ? removePlaylistTrack() : addPlaylistTrack()"
         >
            <MinusCircleIcon v-if="track.hasBeenSelected" class="h-8" />
            <PlusCircleIcon v-if="!track.hasBeenSelected" class="h-8" />
         </i>
      </div>
      <div class="track-info-album-artist" :class="getTrackInfoClasses()">
         <p>album: {{ track.album.name }}</p>
         <p>by: {{ track.artists[0].name }}</p>
      </div>
   </div>
</template>

<!-- This has to have the full <script></script> tags. Otherwise, the linter freaks out. -->
<script lang="ts" src="./TrackInfo.ts"></script>

<!-- Add "scoped" attribute to limit the SCSS to this component only. -->
<style lang="scss" scoped src="./TrackInfo.scss" />
