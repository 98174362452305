/* ========================================================================== */
// ALL REQUIRED IMPORTS
/* ========================================================================== */
// Vue
// Packages
import { faker } from '@faker-js/faker';
import chalk, { ChalkInstance } from 'chalk';
// Context / Stores / Routers
// Components / Classes / Controllers / Services
// Assets
// Constants / Models / Interfaces / Types
import type { AxiosError } from 'axios';
import type { Track } from '../@types/components/models.d';
// Utils / Methods / Mocks / Decorators
// Styles

/* ========================================================================== */
// INTERNAL HELPERS, INTERFACES, VARS & SET UP
/* ========================================================================== */
const debugChalk: ChalkInstance = chalk.blue.italic;
const errorChalk: ChalkInstance = chalk.black.bgRed;
const infoChalk: ChalkInstance = chalk.black.bgCyan;
const successChalk: ChalkInstance = chalk.black.bgGreen;
const warnChalk: ChalkInstance = chalk.black.bgYellow;

/* ========================================================================== */
// DEFINING THE GLOBAL GENERAL UTILS
/* ========================================================================== */
const capitalize = (value: string): string => {
   return `${value[0].toUpperCase()}${value.slice(1).toLowerCase()}`;
};

export const generateFakeTracks = (numTracks = 1): Track[] => {
   const fakeTracks: Track[] = [];

   for (let i = 0; i < numTracks; i += 1) {
      fakeTracks.push({
         album: {
            name: `${capitalize(faker.word.adverb())} ${capitalize(
               faker.word.adjective(),
            )} ${faker.music.genre()}`,
         },
         artists: [
            {
               name: `${faker.name.firstName()} ${faker.name.lastName()}`,
            },
         ],
         hasBeenSelected: false,
         name: faker.music.songName(),
      } as Track);
   }

   return fakeTracks;
};

// NOTE :: This `login` helper will get triggered if a user clicks on the login button or if they try to do a search first
export const generateRandomString = (stringLength: number): string => {
   let text = '';
   const possibleChars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';

   for (let i = 0; i < stringLength; i += 1) {
      text += possibleChars.charAt(Math.floor(Math.random() * possibleChars.length));
   }

   return text;
};

export const getSpmBaseUrl = (isProd: boolean, port = 8888): string => {
   const localMsg = `local ${port}`;
   console.info(infoChalk(`getSpmBaseUrl: Returning ${isProd ? 'prod' : localMsg} URL.`));
   return isProd ? 'https://www.spotifyplaylistmaker.app' : `http://localhost:${port}`;
};

// TODO **[G]** :: Re-evaluate if I really need this
export const isAxiosError = (error: any): error is AxiosError => {
   return error.isAxiosError;
};

export const isSpotifyCallbackPresent = (): boolean => {
   const { origin, pathname } = window.location;

   return (
      `${origin}${pathname}` === 'https://spotifyplaylistmaker.app/callback' ||
      `${origin}${pathname}` === 'http://localhost:8888/callback' ||
      `${origin}${pathname}` === 'http://localhost:3000/callback'
   );
};

export const sortTracks = (tracksList: Track[]): Track[] => {
   return tracksList.sort((a: Track, b: Track) => {
      return a.name.localeCompare(b.name);
   });
};
