/* ========================================================================== */
// ALL REQUIRED IMPORTS
/* ========================================================================== */
// Vue
import { defineComponent } from 'vue';
// Packages
import classNames from 'classnames';
import lodash from 'lodash';
// Context / Store / Router
import { useAppStore } from '@stores/app.store';
import { usePlaylistStore } from '@stores/playlist.store';
import { useSearchStore } from '@stores/search.store';
// Components / Classes / Controllers / Services
import ActionButton from '@comps/ActionButton';
import TrackInfo from '@comps/TrackInfo';
// Assets
// Constants / Models / Interfaces / Types
import {
   ActionButtonLabels,
   REFRESH_TOKEN_MESSAGE,
   SavePlaylistResponse,
} from '@/constants/global';
import type { UserPlaylistData } from '../../../@types/components/data.d';
import type { VoidFunction } from '../../../@types/global.d';
// Utils / Methods / Mocks
import { spotify } from '@utils/Spotify';
// Styles

/* ========================================================================== */
// INTERNAL HELPERS, INTERFACES, VARS & SET UP
/* ========================================================================== */
/* ========================================================================== */
// DEFINING THE 'USER PLAYLIST' COMPONENT
/* ========================================================================== */
export default defineComponent({
   name: 'UserPlaylist',
   components: {
      ActionButton,
      TrackInfo,
   },
   props: {},
   setup() {
      // TODO **[G]** :: Add `Store` types here?
      const appStore = useAppStore();
      const playlistStore = usePlaylistStore();
      const searchStore = useSearchStore();

      return {
         appStore,
         playlistStore,
         searchStore,
      };
   },
   data(): UserPlaylistData {
      return {
         actionButtons: [
            // Keep these in the order that you want them displayed
            {
               class: 'user-playlist-save mr-2',
               handler: this.handleSave as VoidFunction,
               id: lodash.uniqueId('action-button-'),
               label: ActionButtonLabels.SavePlaylist,
               testId: '$user-playlist-save',
            },
            {
               class: 'user-playlist-clear ml-2',
               handler: this.handleClearPlaylist as VoidFunction,
               id: lodash.uniqueId('action-button-'),
               label: ActionButtonLabels.ClearPlaylist,
               testId: '$user-playlist-clear',
            },
         ],
         componentName: 'UserPlaylist',
      };
   },
   computed: {},
   watch: {},
   beforeCreate(): void {},
   beforeMount(): void {},
   beforeUnmount(): void {},
   beforeUpdate(): void {},
   created(): void {},
   mounted(): void {},
   unmounted(): void {},
   updated(): void {},
   methods: {
      handleClearPlaylist(): void {
         this.searchStore.setSearchErrorMessage('');
         this.searchStore.setShowSearchError(false);
         this.playlistStore.clearPlaylist();
      },
      handleInput(event: Event): void {
         // NOTE **[G]** :: Does Spotify impose a character limit here?
         // NOTE **[G]** :: If so, do I want to show an error message?
         const target = event.target as HTMLInputElement;
         this.playlistStore.setPlaylistName(target.value);
      },
      async handleSave(): Promise<void> {
         console.debug(
            `UserPlaylist.handleSave: Attempting to save playlist "${this.playlistStore.playlistName}" to Spotify.`,
         );

         const response: SavePlaylistResponse = await spotify.savePlaylist(
            this.playlistStore.$state,
         );

         if (response?.shouldRefreshToken) {
            console.info(`UserPlaylist.handleSave: ${REFRESH_TOKEN_MESSAGE}`);
            await spotify.refreshAccessToken();
         } else if (response?.isSaved) {
            // TODO **[G]** :: Update show message banner to say playlist was successfully saved
            // Do I want to clear tracks/things/names from here or leave them?
            // What happens if the user hits "save" again w/o changing anything?
         } else {
            // TODO **[G]** :: Update show message banner to say something went wrong with saving
            // If the access token is still good but something goes wrong with saving, do I want/need to do anything more?
         }
      },
      // TODO **[G]** :: Convert `isButtonDisabled` to general util?
      isButtonDisabled(label: string): boolean {
         if (label === ActionButtonLabels.SavePlaylist) {
            return (
               !this.playlistStore.playlistName.length || !this.playlistStore.selectedTracks.length
            );
         }

         return !this.playlistStore.selectedTracks.length;
      },
      getUserPlaylistContainerClasses(): string {
         return classNames('flex flex-col p-6', {
            'ml-6': !this.appStore.isMobile,
         });
      },
      getUserPlaylistHeaderClasses(): string {
         return classNames('font-normal self-start underline');
      },
      getUserPlaylistListClasses(): string {
         return classNames('flex flex-col pt-4');
      },
      getUserPlaylistNameInputClasses(): string {
         return classNames('bg-transparent outline-none text-left', {
            // TODO **[G]** :: Look into using Tailwind's breakpoint aliases to apply some of these stylings
            'ml-4 pt-1.5': !this.appStore.isMobile,
            'mt-4': this.appStore.isMobile,
         });
      },
      getUserPlaylistTitleClasses(): string {
         return classNames('flex', {
            'flex-col': this.appStore.isMobile,
         });
      },
      navigate(): void {
         this.appStore.setCurrentMobileNavLocation('search-results');
      },
   },
});
